import React from 'react';

import './InfoBar.css';


const InfoBar = ({ room, time }) => (
  <div className="infoBar">
    <div className="leftInnerContainer">
        <div className="infoContent">
          <p className="roomName">{room}</p> 
          <p className="roomTime">{time}</p>
        </div>      
    </div>
  </div>
);

export default InfoBar;