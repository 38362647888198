import React, { useState }  from 'react';



import "./Message.css";

import ReactEmoji from "react-emoji";

const Message = ({
  message: { id, text, user, time, is_deleted },
  name,
  nowUsername,
  nowDate,
}) => {
  let isSentByCurrentUser = false;
  console.log(id);  //ini id chat buat di hapus y
  const trimmedName = name.trim().toLowerCase();
  const  [renderedChatId, setRenderedChatId] = useState([]);
  const formatTime = function (t) {
    var date = new Date(t * 1000);
    var hours = date.getHours();
    var minutes = "0" + date.getMinutes();
    var formattedTime = hours + ":" + minutes.substr(-2);
    return formattedTime;
  };

  const formatDate = function (t) {
    var today = new Date();
    var formattedToday =
      today.getDate() + "-" + today.getMonth() + 1 + "-" + today.getFullYear();

    var date = new Date(t * 1000);
    var formattedDate =
      date.getDate() + "-" + date.getMonth() + 1 + "-" + date.getFullYear();

    if (formattedToday != formattedDate) {
      return formattedDate;
    } else {
      return "";
    }
  };
  if (user.trim().toLowerCase() === trimmedName) {
    isSentByCurrentUser = true;
  }
  
  if(!renderedChatId.includes(id)){    
    setRenderedChatId(renderedChatId => [...renderedChatId ,id]); 
  }
  return (
    <>      
      {isSentByCurrentUser ? (
        <div id={id}>
          <div className="messageContainer justifyEnd">
            <p className="sentText pr-10 sentTextTime">{formatTime(time)}</p>
            <div className="messageBox backgroundBlue">              
                {is_deleted == 1 ? (
                  <p className="messageText colorWhite unsent">
                  MESSAGE UNSENT
                  </p>
                )
                   : 
                   (
                    <p className="messageText colorWhite">
                    {ReactEmoji.emojify(text)}
                    </p>
                  )                   
                }              
            </div>
          </div>
        </div>
      ) : (
        <div id={id}>
          {nowUsername !== user && (
            <p className="sentText pl-10 username">{user}</p>
          )}
          <div className="messageContainer justifyStart">
            <div className="messageBox backgroundLight">
              {is_deleted == 1 ? (
                  <p className="messageText colorDark unsent">
                  MESSAGE UNSENT
                  </p>
                )
                   : 
                   (
                    <p className="messageText colorDark">
                    {ReactEmoji.emojify(text)}
                    </p>
                  )                   
                }
            </div>
            <p className="sentText pl-10 ">{formatTime(time)}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default Message;
