import React, { useState, useRef }  from 'react';
import Message from './Message/Message';

import './Messages.css';

const Messages = ({ messages, name, loadMore, unsend} ) => {
  const counterEl = useRef(null);
  
  const checkScroll = (event) => {  
    
    let elm = counterEl.current;
    let sc = parseInt(elm.scrollHeight - Math.abs(elm.scrollTop));
    let ch = elm.clientHeight;
    
    if((sc === ch) || (sc + 1 === ch) || (sc - 1 === ch)){        
      loadMore();
    }  
  }
  const clickUnsend = function(msg_id){ //panggil ini
    unsend(msg_id);
  }
  const formatDate = function (t) {
    const months = [ 
      "Jan", "Feb",  
      "Mar", "April", "May",  
      "Jun", "Jul", "Aug", 
      "Sep", "Oct",  
      "Nov", "Dece" 
  ]; 
    var today = new Date();
    var formattedToday =
      today.getDate() + "-" + today.getMonth() + 1 + "-" + today.getFullYear();

    var date = new Date(t * 1000);
    var formattedDate =
      date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();

    if (formattedToday != formattedDate) {
      return formattedDate;
    } else {
      return "";
    }
  };
  return (
    <div className="messages" ref={counterEl} onScroll={e => checkScroll(e)}>      
      {
      messages.map((message, i) => 
          {   
            
            let nowDate = i < messages.length - 1 ? messages[i+1].time : message.time;
            // if(!renderedChatId.includes(message.id) && message.id){    
            //   setRenderedChatId(renderedChatId => [...renderedChatId ,message.id]); 
              // console.log(renderedChatId);
              return (
                <>
                <div key={message.id}><Message message={message} name={name} nowUsername={i < messages.length - 1 ? messages[i+1].user : message.user}/></div>                
                {formatDate(nowDate) != formatDate(message.time) && (
                  <div className="dateContainer" style={{
                    zIndex: 100+(messages.length - i)
                  }}>
                    <div className="dateText">{formatDate(nowDate)}</div>
                  </div>
                )}                
                </>  
              );         
            }         
          // }
        )        
      }
    </div>
  );
}

export default Messages;