import React, { useState, useEffect } from "react";
import queryString from 'query-string';
import io from "socket.io-client";
import 'whatwg-fetch';
import Messages from '../Messages/Messages';
import InfoBar from '../InfoBar/InfoBar';
import Input from '../Input/Input';
import './Chat.css';
import config from '../config'; 
const ENDPOINT = config.ENDPOINT;
const API_URL = config.API_URL;
let socket;

const Chat = ({ location }) => {    
  const [name, setName] = useState('');
  const [valid, setValid] = useState(false);
  const [room, setRoom] = useState('');
  const [users, setUsers] = useState('');  
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);  
  const [roomName, setRoomname] = useState('');
  const [roomTime, setRoomtime] = useState('');
  const [minChatId, setMinChatId] = useState('');
  useEffect(() => {
    
    const {t} = queryString.parse(location.search);
    socket = io(ENDPOINT);
    fetch(API_URL + '/index.php?r=chatApi/checkToken', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        t: t
      })
    }).then(response => response.json())
      .then(data => {        
        if(data.valid == 1){          
          
          setValid(true);
          setRoom(data.room.id);
          setName(data.user.name);
          setRoomname(data.room.name);
                    
        } else if (data.valid == 0){
          alert('Anda tidak berhak melakukan chat disini');
          return;
        } else {
          setRoom(data.room.id);
          setName(data.user.name);
        }      
        
        socket.emit('join', { name : data.user.name, room : data.room.id + '' }, (error) => {
          if(error) {
            alert(error);
          }
        });
      });        
  }, [ENDPOINT, location.search]);
  
  useEffect(() => {
    socket.on('message', message => {           
      setMessages(messages => [ message, ...messages ]);
    });

    socket.on('oldMessage', message => {    
      setMinChatId(message['id']);
      setMessages(messages => [ ...messages, message ]);
    });
    
    socket.on("roomData", ({ users }) => {
      setUsers(users);
    });
  }, []);

  const sendMessage = (event) => {
    event.preventDefault();
    const {t} = queryString.parse(location.search);    
    if(message) {
      socket.emit('sendMessage', {key: t, msg: message}, () => setMessage(''));
    }
  }

  const unsend = (msg_id) => { //panggil ini mestinyaw
    const {t} = queryString.parse(location.search);
    socket.emit('unsend', { key : t, msg_id: msg_id});
  }


  const loadMore = () => {
    socket.emit('loadMore', { name : name, room : room + '' , last: minChatId}, () => setMessage(''));
  }
  
  
  if(valid){
      return (
        <div className="outerContainer">        
          <div className="container">
              <InfoBar room={roomName} time={roomTime}/>
              <Messages messages={messages} name={name} loadMore={loadMore} unsend={unsend}/>              
              <Input message={message} setMessage={setMessage} sendMessage={sendMessage} />
          </div>
        </div>
      );
  } else {
    return (
      <div className="outerContainer">
        <div className="container">
            <InfoBar room={roomName} time={roomTime} />
            <Messages messages={messages} name={name} />
        </div>
      </div>
    );
  }
  
}




export default Chat;

